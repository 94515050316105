import { module as modular } from 'modujs';

export default class extends modular {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                btn: 'togglefilters',
                reset: 'reset',
                select: 'select',
                open: 'toggleSidebar',
                resetall: 'resetall',
                refresh: 'refresh',
                pagination: 'pagination',
                changeMonth: 'refreshMonth',
                reset: 'reset',
                checked: 'checked',
            },
            change: {
                change: 'refresh',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        if (this.getData('init')) {
            setTimeout(() => {
                this.refresh();
            }, 500);
        }
    }

    refresh() {
        console.log('ici');
        let form = $(this.el);
        let t = this;
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function (response) {
                if ($('.b-relation_listing').length) {
                    $('.b-relation_listing').html(response.content);
                }
                // setTimeout(() => {
                //     t.call('update', false, 'Scroll'); // update Locomotive Scroll
                //     t.call('scrollTo', '.b-relation_listing', 'Scroll');
                // }, 500);
            },
        });
    }

    checked(e) {
        const target = e.currentTarget;
        const items = target;
        let check = false;

        for (let i = 0; i < 4; i++) {
            if (items[i].classList.contains('is-active')) {
                items[i].classList.remove('is-active');
                check == true;
            }
        }

        if (check !== true) {
            item.classList.add('-checked');
        }
    }
}
