import {
    module
} from 'modujs';
import datepicker from 'js-datepicker';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                rent: 'toggleRent',
                submitAjax: 'submitAjax',
                contact: 'toggleContact',
                book: 'toggleBook',
                slide: 'toggleSlide',
                toggleStep: 'toggleStep',
            },

            change: {
                changeRent: 'toggleChange',
            }
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
        this.today = new Date();
        this.dateMax = new Date(new Date().setMonth(this.today.getMonth() + 6));
    }

    init() {
        this.intiDatepicker();
    }

    submitAjax(e) {
        let module = this;
        let el = e.currentTarget;
        let form = $(el).parents('form');
        let error_container = document.getElementById('c-form_msg');

        console.log(form.serialize());

        $.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function (response) {
                if(response.status == 'success') {
                    if($('.b-reservation_content').length) {
                        $('.b-reservation_content').html(response.content);
                    }
                    module.call('scrollTo', '.b-reservation_content', 'Scroll');
                }

                if(response.status == 'error') {
                    error_container.innerHTML = response.msg;
                    error_container.classList.add('u-margin-top-small');
                }
            },
        });
    }

    toggleBook(){
        $('#form-period input[name="action"]').remove();
        let formPeriod = $('#form-period').serialize();
        let formContact = $('#form-contact').serialize();
        let form = formPeriod + '&' + formContact;
        let reg = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');

        if(document.getElementById('mail').value.match(reg) && firstname.value && lastname.value && phone.value){
            $.ajax({
                url: this.ajaxurl,
                type: 'POST',
                data: form,
                success: function (response) {
                    if (response.success) {
                        $('#thirdStep').addClass('is-validate');
                        window.location = response.page_remerciement + '?orderID=' + response.orderID;
                    }
                },
            });
        } else{
            alert("Certains champs sont vides ou incorrects !");
        }
    }

    toggleSlide(e){
        let val = e.currentTarget.getAttribute('data-id');

        if(val == '0' && e.currentTarget.classList.contains('is-active')){
            document.getElementsByClassName('b-reservation_container')[0].style.transform = `translateX(${val}%)`;
        }
        if (val == '-200' && e.currentTarget.classList.contains('is-active')) {
            document.getElementsByClassName('b-reservation_container')[0].style.transform = `translateX(calc(${val}% - 6rem))`;
        }
        if(val !== '0' && val !== '-200' && e.currentTarget.classList.contains('is-active')){
            document.getElementsByClassName('b-reservation_container')[0].style.transform = `translateX(calc(${val}% - 3rem))`;
        }
    }

    toggleStep(e) {
        let module = this;
        let el = e.currentTarget;
        let step = el.getAttribute('data-step');
        $.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {'action': 'toggleStep', 'step': step},
            success: function (response) {
                if($('.b-reservation_content').length) {
                    $('.b-reservation_content').html(response.content);
                }
                module.intiDatepicker();
                module.call('scrollTo', '.b-reservation_content', 'Scroll');
            },
        });
    }

    intiDatepicker() {
        if($('.c-datepicker').length) {
            $.ajax({
                url: this.ajaxurl,
                type: 'GET',
                data: { action: 'getDisabledDates' },
                success: function (response) {
                    let disableDay = false;
                    let days = new Array;
                    let disableWeekly = false;
                    let disableYearly = new Array;
                    if(response['disableDays']) {
                        disableDay = response['disableDays'];
                        disableDay.forEach(day => {
                            days.push(new Date(day));
                        });
                    }
                    if(response['disableWeeks']) {
                        disableWeekly = response['disableWeeks'];
                    }
                    
                    const picker = datepicker('.datepicker', {
                        customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                        customDays: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
                        startDay: 1,
                        formatter: (input, date, instance) => {
                            const value = date.toLocaleDateString()
                            input.value = value // => '1/1/2099'
                        },
                        minDate: new Date(),
                        maxDate: this.dateMax,
                        disabledDates: days,
                        disabler: date => {
                            if(disableWeekly)
                                (disableWeekly.includes(date.getDay()))
                        }
                    });
            
                    const picker2 = datepicker('.datepicker2', {
                        customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                        customDays: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
                        startDay: 1,
                        formatter: (input, date, instance) => {
                            const value = date.toLocaleDateString()
                            input.value = value // => '1/1/2099'
                        },
                        minDate: new Date(),
                        maxDate: this.dateMax,
                        disabledDates: days,
                        disabler: date => {
                            if(disableWeekly)
                                (disableWeekly.includes(date.getDay()))
                        }
                    });
                },
            });
        }
    }
}
